<template>
  <v-app>
<v-app-bar shrink-on-scroll
  app hide-overlay absolute clipped-left dark prominent :src='logoStr' :dense='minimised'
      scroll-target="scrolling-techniques-2">
      <!-- :src='bannerStr' -->
      <div>
        <v-app-bar-nav-icon v-if="haveUser" @click="toggleModules">
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon @click="toggleFullscreen">
        </v-app-bar-nav-icon>
      </div>
      <v-img :src='bannerStr' contain="contain" max-height="150" v-show="minimised"></v-img>
      <v-toolbar-title>{{ getSiteName }}</v-toolbar-title>
      <template v-slot:extension>
<v-tabs align-with-title
  background-color="transparent" v-on:change="loadModuleOptions" v-model="activeTab">
          <v-tab v-for="module in siteModules" :key="module" @click="loadLandingPage(module)">
            {{ module }}
          </v-tab>
        </v-tabs>
      </template>

      <v-spacer></v-spacer>
      <!-- <template slot:extension> -->
      <!-- <v-container v-if="searchSettings">-->
      <!--       <SearchAutoComplete-->
      <!--       filled dense-->
      <!--       v-bind:controlData="searchSettings"-->
      <!--         name="name"-->
      <!--         id="id"-->
      <!--         outlined-->
      <!--         placeholder="Type to search tests, materials, quotes, and more..."-->
      <!--         no-data-text="This is a test feature...please be patient!"-->
      <!--       ></SearchAutoComplete>-->
      <!-- </v-container>-->
      <!-- </template> -->
      <v-spacer></v-spacer>
      <ProfileDropDownScreen v-if="haveUser">
      </ProfileDropDownScreen>

    </v-app-bar>
    <v-navigation-drawer v-model="showModuleOptions" app hide-overlay clipped>
      <v-list nav block>
        <v-list-item v-for="option in Object.keys(moduleOptions)" :key="option">
          <div v-if="option != ''">
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn color="primary" v-bind="props" block>
                  {{ option }}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in moduleOptions[option]" :key="index"
                  @click="loadPage(item.routeName)">
                  <v-list-item-title>{{ item.viewName }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div v-else>
            <v-list>
              <v-list-item block v-for="(item, index) in moduleOptions[option]" :key="index"
                @click="loadPage(item.routeName)">
                  <v-btn block dark color="primary" @click="loadPage((option as any).routeName)">
                    {{ item.viewName }}
                  </v-btn>
              </v-list-item>
            </v-list>
          </div>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <div>
      <v-app-bar-nav-icon v-if="haveUser" @click="toggleModules">
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon @click="toggleFullscreen">
      </v-app-bar-nav-icon>
    </div>
    <v-main>
      <v-content :fluid="true">
        <SignIn v-if="!haveUser"></SignIn>
        <router-view :key="$route.fullPath"></router-view>
      </v-content>
    </v-main>
    <v-footer app>
      {{ getSiteFooterText }}
    </v-footer>
  </v-app>
</template>
<script lang="ts">
// import Vue from 'vue';
// clean build

import {
  AppAxiosInstance,
  ProfileDropDownScreen,
  UserLoadSite,
  BeforeNavigate,
  AppError,
  SignIn,
  GenericView,
  SearchAutoComplete,
  NavigationControl,
} from '@quickdrawltd-org/eden/src/main';
// import { ViewDefault } from './interfaces/IView';
import { mapState } from 'vuex';
import {
  Component, Watch, Vue,
} from 'vue-facing-decorator';
import { Hub } from 'aws-amplify/utils';
import Router from './router';

@Component({
  // computed: mapGetters(['getSiteName', 'getSiteBanner', 'getSiteLogo', 'getSiteFooterText']),
  components: {
    ProfileDropDownScreen,
    GenericView,
    BeforeNavigate,
    AppError,
    SearchAutoComplete,
    NavigationControl,
    SignIn,
  },
})
export default class App extends Vue {
  name = 'App';

  router = Router.router;

  getSiteLogo: string;

  minimised = false;

  activeTab = null;

  toggleFullscreen() {
    this.minimised = !this.minimised;
  }

  // eslint-disable-next-line camelcase
  getSiteName: string;

  getSiteFooterText: string;

  @Watch('getSiteBanner')
  onSiteLogoChanged(val: any) {
    this.logoStr = window.URL.createObjectURL(val);
  }

  @Watch('getSiteLogo')
  onSiteBannerChanged(val: any) {
    this.bannerStr = window.URL.createObjectURL(val);
  }

  @Watch('getSiteName')
  onSiteTitleChanged(val: any) {
    this.title = val;
    document.title = this.title;
  }

  logoStr = '';

  bannerStr = '';

  contain = true;

  // siteName: string = '';

  signOutVisible = false;

  moduleOptions = {};

  appAxios: AppAxiosInstance = AppAxiosInstance.getInstance();

  userLoadSite: UserLoadSite;

  siteModel: NonNullable<unknown>;

  appState = '';

  title = 'test';

  showModuleOptions = true;

  getRoute() {
    const x = this.router.currentRoute;
    console.log(`x::::${x}`);
    const y = this.router.currentRoute.value;
    console.log(`y::::${y}`);
    const z = this.router.currentRoute.value.path;
    console.log(`z::::${z}`);
  }

  loadLandingPage(module: any) {
    const options = this.$store.getters.getSubModules(module);
    // eslint-disable-next-line
    const landingPage = options.find((pages: { isLandingPage: boolean; }) => pages.isLandingPage === true);
    this.$router.push(landingPage.route);
  }

  toggleModules() {
    this.showModuleOptions = !this.showModuleOptions;
  }

  loadModuleOptions(moduleNumber: number) {
    const moduleName = this.$store.getters.getModules[moduleNumber];
    const tempModuleOptions = this.$store.getters.getSubModules(moduleName) || [];
    // eslint-disable-next-line
    const allowedTempModules = tempModuleOptions.filter((x: { display: boolean; }) => x.display === true);
    const moduleMap = {};
    allowedTempModules.forEach((atm: { group: string | number; }) => {
      if (!moduleMap[atm.group]) {
        moduleMap[atm.group] = [];
      }
      moduleMap[atm.group].push(atm);
    });
    this.moduleOptions = moduleMap;
  }

  get siteBanner() {
    const siteBanner = this.$store.getters.getSiteBanner;
    return `data:image/jpeg;base64,${siteBanner}`;
  }

  get searchSettings() {
    const searchSettingsControl = this.$store.getters.getSearchSettings;
    return searchSettingsControl;
  }

  get siteModules() {
    const modules = this.$store.getters.getModules;
    // eslint-disable-next-line no-underscore-dangle
    if (!this.router) {
      return [];
    }
    const currentModule = this.$router.currentRoute.value;
    if (!currentModule) {
      return [];
    }
    // const xxx = this.router.getRoutes().find((x) => x.path === currentModule.path);
    // if (!xxx) {
    //   // TODO
    // } else {
    //   // eslint-disable-next-line
    //   for (const moduleDefinition of Object.keys(this.$store.getters.getSiteDefinition)) {
    //     if (this.$store.getters.getSiteDefinition[moduleDefinition]
    //       .find((x: { route: string; }) => x.route === currentModule.path)) {
    //       this.activeTab = modules.indexOf(moduleDefinition);
    //       this.loadModuleOptions(this.activeTab);
    //       break;
    //     }
    //   }
    //   const x = modules; //  .find
    //   console.log('found it');
    // }
    const resolved = this.$router.resolve(currentModule);
    console.log(`this.$route.fullPath::::${this.$route.fullPath}`);
    if (!resolved) {
      console.log(resolved);
      // this.$router.push('/signIn');
      // we did it
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for (const moduleDefinition of Object.keys(this.$store.getters.getSiteDefinition)) {
        if (this.$store.getters.getSiteDefinition[moduleDefinition]
          .find((x) => x.route === currentModule.path)) {
          this.activeTab = modules.indexOf(moduleDefinition);
          this.loadModuleOptions(this.activeTab);
          break;
        }
      }
      // const x = modules; //  .find
      console.log('found it');
    }

    if (modules === null) {
      this.moduleOptions = [];
    }
    return modules;
  }

  update = mapState(['siteBannerId']);

  get haveUser() {
    if (Object.keys(this.$store.getters.getUserProfile).length === 0) {
      // this.$router.push('/signIn');
      this.moduleOptions = {};
      return null;
    }
    return this.$store.getters.getUserProfile;
  }

  created() {
    this.name = 'bob';
  }

  loadPage(routeName: string) {
    let subModule = null;
    // let module = null;
    // eslint-disable-next-line no-restricted-syntax
    for (const group of Object.keys(this.moduleOptions)) {
      // eslint-disable-next-line
      if (this.moduleOptions[group].find((x: { routeName: string; }) => x.routeName === routeName)) {
        // eslint-disable-next-line
        subModule = this.moduleOptions[group].find((x: { routeName: string; }) => x.routeName === routeName);
        break;
      }
    }
    if (subModule) {
      console.log(`i did the dirty work ${routeName}`);
      this.$router.push(subModule.route);
    }
    // const subModule = this.moduleOptions.find(x => x.routeName === routeName);
  }

  stateChanged(info: string) {
    console.log(`info::::${info}`);
    if (info === 'signedIn') {
      this.signOutVisible = true;
    }
  }

  mounted() {
    // TODO
    Hub.listen('auth', (event) => this.stateChanged(event as any));
    // .$on('authState', this.stateChanged);
  }
}
</script>
<style scoped>
#app>div.v-overlay.v-overlay--active.theme--dark>div.v-overlay__scrim {
  z-index: -600;
}
</style>
