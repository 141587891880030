import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b8156474"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_ProfileDropDownScreen = _resolveComponent("ProfileDropDownScreen")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_SignIn = _resolveComponent("SignIn")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_content = _resolveComponent("v-content")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_footer = _resolveComponent("v-footer")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar, {
        "shrink-on-scroll": "",
        app: "",
        "hide-overlay": "",
        absolute: "",
        "clipped-left": "",
        dark: "",
        prominent: "",
        src: _ctx.logoStr,
        dense: _ctx.minimised,
        "scroll-target": "scrolling-techniques-2"
      }, {
        extension: _withCtx(() => [
          _createVNode(_component_v_tabs, {
            "align-with-title": "",
            "background-color": "transparent",
            onChange: _ctx.loadModuleOptions,
            modelValue: _ctx.activeTab,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.siteModules, (module) => {
                return (_openBlock(), _createBlock(_component_v_tab, {
                  key: module,
                  onClick: ($event: any) => (_ctx.loadLandingPage(module))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(module), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["onChange", "modelValue"])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            (_ctx.haveUser)
              ? (_openBlock(), _createBlock(_component_v_app_bar_nav_icon, {
                  key: 0,
                  onClick: _ctx.toggleModules
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_v_app_bar_nav_icon, { onClick: _ctx.toggleFullscreen }, null, 8, ["onClick"])
          ]),
          _withDirectives(_createVNode(_component_v_img, {
            src: _ctx.bannerStr,
            contain: "contain",
            "max-height": "150"
          }, null, 8, ["src"]), [
            [_vShow, _ctx.minimised]
          ]),
          _createVNode(_component_v_toolbar_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getSiteName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_spacer),
          (_ctx.haveUser)
            ? (_openBlock(), _createBlock(_component_ProfileDropDownScreen, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["src", "dense"]),
      _createVNode(_component_v_navigation_drawer, {
        modelValue: _ctx.showModuleOptions,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showModuleOptions) = $event)),
        app: "",
        "hide-overlay": "",
        clipped: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list, {
            nav: "",
            block: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.moduleOptions), (option) => {
                return (_openBlock(), _createBlock(_component_v_list_item, { key: option }, {
                  default: _withCtx(() => [
                    (option != '')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                          _createVNode(_component_v_menu, null, {
                            activator: _withCtx(({ props }) => [
                              _createVNode(_component_v_btn, _mergeProps({ color: "primary" }, props, { block: "" }), {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(option) + " ", 1),
                                  _createVNode(_component_v_icon, {
                                    right: "",
                                    dark: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("mdi-chevron-right")
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1040)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_v_list, null, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moduleOptions[option], (item, index) => {
                                    return (_openBlock(), _createBlock(_component_v_list_item, {
                                      key: index,
                                      onClick: ($event: any) => (_ctx.loadPage(item.routeName))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_list_item_title, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.viewName), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createVNode(_component_v_list, null, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moduleOptions[option], (item, index) => {
                                return (_openBlock(), _createBlock(_component_v_list_item, {
                                  block: "",
                                  key: index,
                                  onClick: ($event: any) => (_ctx.loadPage(item.routeName))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_btn, {
                                      block: "",
                                      dark: "",
                                      color: "primary",
                                      onClick: ($event: any) => (_ctx.loadPage((option as any).routeName))
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.viewName), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"])
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createElementVNode("div", null, [
        (_ctx.haveUser)
          ? (_openBlock(), _createBlock(_component_v_app_bar_nav_icon, {
              key: 0,
              onClick: _ctx.toggleModules
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_v_app_bar_nav_icon, { onClick: _ctx.toggleFullscreen }, null, 8, ["onClick"])
      ]),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_content, { fluid: true }, {
            default: _withCtx(() => [
              (!_ctx.haveUser)
                ? (_openBlock(), _createBlock(_component_SignIn, { key: 0 }))
                : _createCommentVNode("", true),
              (_openBlock(), _createBlock(_component_router_view, {
                key: _ctx.$route.fullPath
              }))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_footer, { app: "" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getSiteFooterText), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}