import { Router, RouteRecordRaw } from 'vue-router';
import { GenericView, AppAxiosInstance } from '@quickdrawltd-org/eden/src/main'; // IModule
// import store from '@/store';

export default class SiteModel {
    public site: any[]; // IModule

    public selectedModule: any; // IModule

    private router: Router;

    private AppAxios: AppAxiosInstance;

    constructor(
      router: Router,
      axiosInstance: AppAxiosInstance,
    ) {
      this.router = router;
      this.AppAxios = axiosInstance;
    }

    public setSite(site: any[]) { // IModule
      this.site = site;
      this.buildRoutes(this.site[1], []);
      // eslint-disable-next-line
      this.selectedModule = this.site[0];
    }

    public findRoute(baseModule: any, routeName: string): any { // IModule
      baseModule.modules.forEach((module) => {
        if (module.routeName === routeName) {
          return module;
        }
        return this.findRoute(module, routeName);
      });
      return null;
    }

    public async buildRoutes(module: any, routeConfigs: RouteRecordRaw[]): Promise<void> {
      // IModule
      if (!module) {
        await this.loadSite().then(() => {
          if (this.site) {
            this.createRoutes(this.site[1], []);
          }
        });
      }
      this.createRoutes(module, []);
    }

    private createRoutes(module: any, routeConfigs: RouteRecordRaw[]): void { // IModule
      module.modules.forEach((element: any) => {
        this.buildRoutes(element, routeConfigs);
      });
      // TODO: Fix
      this.router.addRoute(
        ({
          name: module.routeName,
          path: module.route,
          redirect: null,
          component: GenericView,
          props: true,
          meta: { requiresAuth: true },
        } as any),
      );

      // this.router.addRoutes(routeConfigs);
    }

    private loadSite(): Promise<void> {
      return new Promise((res, rej) => {
        AppAxiosInstance.Instance.get<any>('/view/5dcc0a12bdf6779ba4ff0c9f',
        // eslint-disable-next-line
              ).then((res) => {
          // store.state.siteDefinition = res.data.module;
          // eslint-disable-next-line
          this.setSite(res.data.modules);
        });
      });
    }
}
