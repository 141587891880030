// import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
// import HomeView from '../views/HomeView.vue';

// const routes: Array<RouteRecordRaw> = [
//   {
//     path: '/',
//     name: 'home',
//     component: HomeView,
//   },
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
//   },
// ];

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes,
// });

// export default router;

import {
  RouteRecordRaw, Router, createRouter, createWebHistory,
} from 'vue-router';
import { Hub } from 'aws-amplify/utils';
// import { AmplifyEventBus } from 'aws-amplify-vue';
import {
  AppAxiosInstance, getUser, baseRoutes,
} from '@quickdrawltd-org/eden/src/main';
import SiteModel from '../partBuilder/siteBuilder';
// import baseRoutes from './baseRoutes';

// Vue.use(VueRouter);

export default class RouterX {
  AppAxios: AppAxiosInstance;

  constructor() {
    this.AppAxios = AppAxiosInstance.getInstance();
    // this.baseRoutes().then(async (brs) => {
    RouterX.router = createRouter({
      // : process.env.BASE_URL,
      sensitive: false,
      history: createWebHistory(),
      routes: [],
    });

    RouterX.router.beforeResolve(async (to, from, next) => {
      const user = await getUser();
      if (!user) {
        if (to.matched.some((record) => record.meta.requiresAuth)) {
          return next({
            path: '/signIn',
          });
        }
      }
      return next();
    });
    // });
  }

  // eslint-disable-next-line class-methods-use-this
  public setBaseRoutes(siteBaseRoutes: RouteRecordRaw[]) {
    siteBaseRoutes.forEach((route) => {
      console.log(`route::::${JSON.stringify(route)}`);
      RouterX.router.addRoute(route);
    });
    // RouterX.router.addRoutes(siteBaseRoutes);
  }

  public static router: Router;

  public baseRoutes = (): Promise<any> => new Promise<any>((res, rej) => {
    baseRoutes.loadBaseRoutes().then((results) => res(results));
  })

  // public routes: RouteConfig[] = baseRoutes.getRoutes(Router.router);

  siteModel: SiteModel;

  public async initizalise(): Promise<void> {
    this.siteModel = new SiteModel(RouterX.router, this.AppAxios);
    Hub.listen('authState', async (state) => {
      const pushPathes = {
        forgotPassword: () => {
          RouterX.router.push({ path: '/forgotpassword' });
        },
        signedOut: () => {
          RouterX.router.push({ path: '/signIn' });
        },
        signUp: () => {
          RouterX.router.push({ path: '/signUp' });
        },
        confirmSignUp: () => {
          RouterX.router.push({ path: '/signUpConfirm' });
        },
        signIn: () => {
          RouterX.router.push({ path: '/signIn' });
        },
        signedIn: () => {
          RouterX.router.push({ name: 'home' });
        },
      };
      // if (typeof pushPathes[state] === 'function') {
      //   pushPathes[state]();
      // }
    });
  }
}
